import React from "react";
import Layout from "../components/layout";
import "../styles/pages/about.scss";
export default function Home() {
  return (
    <Layout title="About">
      <h1 className="page-title">About</h1>
      <div className="about-page">
        <h3>
          We are actively uniting, coordinating and involving all members from
          our area for an ultimate and a genuine cause:
          <span> Development of our area</span>
        </h3>

        <p>
          In connection with this, we had conducted many meetings and it was
          decided to form an association as an outcome. Office bearers were
          elected unanimously.
        </p>

        <p>
          The first and the foremost task for us were to move for - requesting
          basic amenities i.e. metro water and drainage facilities, roads and
          street lights. We made petitions to respective departments requesting
          the same.
        </p>

        <video autoPlay muted loop className="map-video">
          <source
            src="https://res.cloudinary.com/sivadass/video/upload/v1639932778/nlrrwa/branding/map-3d-low.mp4"
            type="video/mp4"
          />
        </video>

        <p>
          We were successful in getting street lights for Sri Ganapathy Nagar
          2nd main road & it’s four cross streets. It was inaugurated by our
          area MLA Aravind Ramesh sir.
        </p>

        <p>
          Subsequently, it was decided during one of our meetings, that we have
          to raise the level of existing roads before the govt. lays the road,
          to avoid water logging. Hence, respective street members contributed
          an amount equally and raised their road levels with rough pieces. As
          you are aware, it was levelled with Road rollers today.
        </p>

        <p>
          In the mean time, Officials have assured us that work against our
          petitions are in progress.
        </p>

        <p>
          Then, we made a road survey, met members from all streets/main roads
          and explained our cause. Consolidated the data from our road survey
          and enclosed it with our new petition, requesting basic amenities for
          the entire locality and submitted the same to respective government
          offices.
        </p>

        <p>
          Now, to make it clear to all once again, this association is formed
          with the sole intention of developing our area. Association will not
          indulge, entertain or tolerate any form of financial scams.
        </p>

        <p>
          Let’s unite for a common cause and make things happen faster, which
          will definitely benefit everyone socially and individually.
        </p>
        <div>
          <h3>Details of Activities carried out by the Association:</h3>
          <p>
            The purpose and the objectives of our residential welfare
            association are as follows:
          </p>
          <ul>
            <li>
              To make best efforts for creating mutual harmony, cooperation and
              coordination amongst the residents/members.
            </li>

            <li>
              To take up the matter with the competent authorities for the
              common interest of the residents for providing and/or improving
              common facilities in our area like – roads, street lights, metro
              water connection, sewage, storm water drainage, parks, electricity
              supplies, ration shops etc.
            </li>

            <li>
              To represent on behalf of the members to various authorities of
              Government or other agencies.
            </li>

            <li>
              To make adequate arrangements for old aged, children and women to
              experience a serene atmosphere and a peaceful environment.
            </li>

            <li>
              To approach the concerned authorities for redressal of grievances
              of members of association.
            </li>

            <li>
              To enhance the safety level of our area, prohibit debauchery and
              immoral activities during night time, by arranging frequent
              patrols of police department personnel.
            </li>

            <li>
              To receive/ collect one time membership charge of Rs.500/- per
              family and subscription charge of Rs.50/- per month through online
              methods/cheques. The income of the association shall be solely
              utilized for fulfilment of the purpose and objectives of the
              association only.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
